<template>
  <b-modal
    id="approve-withdraw-modal"
    :title="approveType? (approveType ==='auto'?'ถอนออโต้':'ถอนมือ'):`${$t('credits.direct_deposit')}`"
    hide-footer
    :header-class="approveType&&approveType ==='auto'?'bg-gradient-info':'bg-gradient-warning'"
    :body-class="approveType&&approveType ==='auto'?'bg-gradient-info':'bg-gradient-warning'"
  >
    <b-row>
      <b-col md="6">
        <b-card
          v-if="txn && txn.user"
          bg-variant="dark"
          text-variant="white"
          class="text-center"
        >
          <b-card-text>
            <h4 class="text-white">
              ยอดฝากรวมทั้งหมด
            </h4>
            <h3 class="text-white">
              {{
                txn.user.totalDeposit | currency
              }}
            </h3>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card
          v-if="txn && txn.user"
          bg-variant="dark"
          text-variant="white"
          class="text-center"
        >
          <b-card-text>
            <h4 class="text-white">
              ยอดถอนรวมทั้งหมด
            </h4>
            <h3 class="text-white">
              {{
                txn.user.totalWithdraw | currency
              }}
            </h3>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-card
      bg-variant="primary"
      text-variant="white"
      header-text-variant="primary"
      class="text-center"
    >
      <b-card-text>
        <h3 class="text-white">
          ยอดที่แจ้งถอนมา
        </h3>
        <h1 class="text-white">
          {{ txn.amount | currency }}
        </h1>
      </b-card-text>
    </b-card>

    <div class="text-center">
      <h6>วันที่ {{ txn.createdAt | datetime }}</h6>
    </div>
    <b-dropdown
      id="transferBankAccount"
      class="w-100 mb-3"
      variant="outline-dark"
    >
      <template #button-content>
        เลือกบัญชีที่โอน
      </template>
      <template v-if="withdrawBankAccounts.length > 0">
        <b-dropdown-item
          v-for="bank in withdrawBankAccounts" 
          :key="bank.id"
          block
          @click="selectedBank = bank"
        >
          <b-media vertical-align="center">
            <template #aside>
              <BankIcon :bank-code="bank.bankCode" />
            </template>
            {{ bank.bankAccountNumber }}<br>
            {{ bank.bankAccountName }}
          </b-media>
        </b-dropdown-item>
      </template>
      <b-dropdown-item
        v-else
        disabled
      >
        ไม่มีบัญชี
      </b-dropdown-item>
    </b-dropdown>
    <b-card v-if="selectedBank">
      <b-row align-v="center">
        <b-col>
          <b-media vertical-align="center">
            <template #aside>
              <BankIcon :bank-code="selectedBank.bankCode" />
            </template>
            {{ selectedBank.bankAccountNumber }}<br>
            {{ selectedBank.bankAccountName }}
          </b-media>
        </b-col>
        <b-col cols="2">
          <feather
            type="check-circle"
            class="icon-lg align-middle icon-dual-success"
          />
        </b-col>
      </b-row>
      <template v-if="selectedBank && selectedBank.isRequiredOtp">
        <hr>
        <label
          class="text-danger"
          for="otp"
        >กรุณากรอกเลข OTP ก่อนทำรายการ</label>
        <b-input-group>
          <b-input
            id="otp"
            v-model="approveForm.otp"
          />
          <b-input-group-append>
            <b-overlay
              :show="isRequestingOtp"
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button
                variant="outline-primary"
                @click="onSendOtp"
              >
                ขอรหัส OTP
              </b-button>
            </b-overlay>
          </b-input-group-append>
        </b-input-group>
        <b-form-text v-if="withdrawOtpRef">
          Ref: {{ withdrawOtpRef }}
        </b-form-text>
      </template>
    </b-card>
    <div class="text-right">
      <b-button
        variant="light"
        class="mr-2"
        @click="closeModal"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-overlay
        :show="isLoading"
        rounded
        spinner-small
        class="d-inline-block"
      >
        <b-button 
          type="submit"
          variant="primary"
          block 
          :disabled="selectedBank && selectedBank.isRequiredOtp && !approveForm.otp"
          @click="onSubmit"
        >
          {{ $t('buttons.confirm') }}
        </b-button>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  props: {
    txn: {
      type: Object,
      default: ()=> {},
    },
    approveType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      selectedBank: null,
      approveForm: {
        agentBankAccountId: '',
        isManual: true,
        remark: '',
        ref: '',
        otp: '',
      },
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.withdraw.isApprovingWithdraw,
      isRequestingOtp: (state) => state.withdraw.isRequestingWithdrawOtp,
      isRequestOtpSuccess: (state) => state.withdraw.isRequestingWithdrawOtpSuccess,
      withdrawOtpRef: (state) => state.withdraw.withdrawOtpRef,
    }),
    ...mapGetters(['withdrawBankAccounts', 'withdraws']),
    bankAccounts(){
      const { bankAccounts } = this.withdraws
      if(bankAccounts.length > 0){
        return bankAccounts
      }
      return this.withdrawBankAccounts
    },
  },
  watch: {
    selectedBank(val){
      if(val){
        this.approveForm.agentBankAccountId = val.id
      }
    },
    isRequestOtpSuccess(val){
      if(val === true){
        // this.selectedBank = null
      }
    },
    withdrawOtpRef(val){
      if(val){
        this.approveForm.ref = val
      }
    },
  },
  created() {
    this.fetchWithdrawBankAccounts();
  },
  methods: {
    ...mapActions(['fetchWithdrawBankAccounts', 'approveWithdraw', 'requestWithdrawOtp']),
    closeModal() {
      this.$bvModal.hide('approve-withdraw-modal')
      this.selectedBank = null
      this.approveForm = {
        agentBankAccountId: '',
        isManual: true,
        remark: '',
        ref: '',
        otp: '',
      }
    },
    onSendOtp() {
      this.requestWithdrawOtp({
        id: this.txn.id,
        agentBankAccountId: this.approveForm.agentBankAccountId,
      })
    },
    async onSubmit() {
      if(!this.approveForm.agentBankAccountId){
        this.$store.dispatch('notifyError', 'กรุณาเลือกบัญชี')
      } else {
        this.approveForm.isManual = !!(this.approveType ==='manual')
        await this.approveWithdraw({
          id: this.txn.id,
          data: this.approveForm,
        })
        this.closeModal()
      }
    },
  },
}
</script>
